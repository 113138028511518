@mixin background-img {
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

@mixin basic-modal {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -7px 8px 16px rgba(0, 0, 0, 0.15);
  width: 95%;
  max-width: 840px;
  min-height: 100vh;
  height: 100%;
  z-index: 10;
}

@mixin close-modal {
  border-radius: 50%;
  border: none;
  background: none;
  position: absolute;
  top: 35px;
  left: -25px;
}

@mixin footer-box {
  padding: 30px 20px;
  background: $color-primary;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

/* FLEX */
/* FIRST DIRECTION */
@mixin flex-between-auto {
  display: flex;
  justify-content: space-between;
}

@mixin flex-center-auto {
  display: flex;
  justify-content: center;
}

@mixin flex-around-auto {
  display: flex;
  justify-content: space-around;
}

/* FLEX */
/* SECOND DIRECTION */
@mixin flex-auto-center {
  display: flex;
  align-items: center;
}

/* FLEX */
/* BOTH DIRECTIONS */
@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* FLEX */
/* COLUMN */
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-between-auto {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-between-start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

/* TEXT */
@mixin text-ellipsis {
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

@mixin font-content {
  font-family: 'Inter', sans-serif;
}

@mixin font-btn {
  font-family: 'Roboto', sans-serif;
}

/* BOX */
@mixin box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
}

@mixin basic-card-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background: #fff;
}

@mixin box-hover {
  cursor: pointer;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}
